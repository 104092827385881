import { GaugeChart } from '../Charts/GaugeChart';
import { DashboardWidget } from '../../models/widget';

import WidgetHeader from '../WidgetHeader';
import { SimpleDataBlock, SmallDataBlockContainer } from '../DataBlock';
import { PrevisionsSortiesData } from '../../models/shopData';
import { useEffect, useState } from 'react';
import { getPrevisionsSorties } from '../../api/locationApi';
import dayjs from 'dayjs';
import useWindowDimensions, { useAppSelector } from '../../app/hooks';
import { WidgetContent } from '../WidgetContent';
import { smallWidth } from '../../utils/Const';
import { getRefresh } from '../../utils/Widgets';

interface AvancementSortiesProps {
  shopId: number,
  widget: DashboardWidget
}

export default function AvancementSorties({shopId, widget} : AvancementSortiesProps) {

  
    const date = dayjs().format('YYYY-MM-DD'); 
    const [data, setData] = useState<PrevisionsSortiesData | null>(null);
    const [error, setError] = useState<string | null>(null);

    const groups = useAppSelector((state) => state.user.groups);
    const theme: string = groups.find(el => el.id === -shopId)?.color || 'blue';

    const height = (useWindowDimensions().width < smallWidth ? 205 : 260 ) + 'px';

    useEffect(() => {
        setData(null);
        setError(null);
        getPrevisionsSorties(shopId, date, 5, getRefresh(data?.timestamp || null, widget.refreshTime))
        .then((res) => setData(res))
        .catch((err) => {
            console.log(err);
            setError(err.message);
        });
        
    }, [widget.refreshTime, date]);

    return (
       <div className="Widget">
         <WidgetHeader title="Avancement des sorties de matériel réservé" widget={widget} searchable={false} duplicable={false}/>
            <WidgetContent data={data} widget={widget} error={error} style={{height: height}}>
                {data == null ? 
                    null :
                    <div>
                        <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                            <GaugeChart 
                            percent={data.nbArticleN == 0 ? 0 :(data.nbArticleN-data.nbArticleJN)/data.nbArticleN*100}
                            theme={theme}/>
                        </div>
                        <SmallDataBlockContainer>
                            <SimpleDataBlock libelle="Articles réservés sortis" value={data.nbArticleN-data.nbArticleJN}/>
                            <SimpleDataBlock libelle="Articles réservés restants" value={data.nbArticleJN}/>
                        </SmallDataBlockContainer>
                    </div>
                }
            </WidgetContent>
       </div>
    )
}