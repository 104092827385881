import { useEffect, useState } from 'react';
import WidgetHeader from '../WidgetHeader';
import WidgetSlider from '../WidgetSlider';

import { CaisseModePaiementData } from "../../models/shopData";
import { DataBlock } from '../DataBlock';
import dayjs from 'dayjs';
import { getDateAsString, getDateRange, getRefresh } from '../../utils/Widgets';
import WidgetSearchFrame from '../WidgetSearchFrame';
import { DashboardWidget, DashboardWidgetSlide } from '../../models/widget';
import ChartBlock from '../ChartBlock';
import { WidgetContent } from '../WidgetContent';
import { getCaisseModePaiement } from '../../api/caisseApi';
import { getBarChartHeight } from '../../utils/Charts';
import { euroSymbol, smallWidth } from '../../utils/Const';
import useWindowDimensions from '../../app/hooks';

interface CaisseModePaiementProps {
    shopId: number,
    widget: DashboardWidget
}

interface CaisseModePaiementSlideProps {
    widget: DashboardWidget;
    shopId: number;
    slide: DashboardWidgetSlide;
}


export default function CaisseModePaiement ({shopId, widget} : CaisseModePaiementProps) {

    let [slides, setSlides] = useState<DashboardWidgetSlide[]>(getDateRange(new Date().toISOString(), 4, 0).map((el) => {return {debut: el, fin: el, timestamp: null}}));

    const height = (useWindowDimensions().width < smallWidth ? 275 : 315 ) + 500 +'px';

    return (
       <div className="Widget" style={{minHeight:height}}>
            <WidgetHeader title="Caisse par mode de paiement" widget={widget}/>
            {widget.search ? 
                    <WidgetSearchFrame widget={widget} withDateFin={true}>
                        {widget.searchDateDebut && widget.searchDateFin ?
                            <CaisseModePaiementSlide 
                                widget={widget}
                                shopId={shopId} 
                                slide={{debut: widget.searchDateDebut, fin: widget.searchDateDebut, timestamp: ""}} /> 
                            : null
                        }   
                    </WidgetSearchFrame>
            :  
            <WidgetSlider widget={widget} defaultIndex={slides.map(el => el.debut).indexOf(dayjs().format('YYYY-MM-DD'))}>
                {slides.map(s => {
                    return (
                    <CaisseModePaiementSlide 
                        widget={widget}
                        shopId={shopId} 
                        slide={s} 
                        key={s.debut} />)
                })}
            </WidgetSlider>}
       </div>
    )
}


function CaisseModePaiementSlide ({shopId, slide, widget} : CaisseModePaiementSlideProps) {

    const [data, setData] = useState<CaisseModePaiementData | null>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        setData(null);
        setError(null);
        getCaisseModePaiement(shopId, slide.debut, slide.fin, getRefresh(slide.timestamp, widget.refreshTime))
        .then((res) => {
            setData(res);
            slide.timestamp = res.timestamp;
        })
        .catch((err) => {
            console.log(err);
            setError(err.message);
        });

    }, [widget.refreshTime, slide.debut, slide.fin]);


    return (
        <WidgetContent 
        data={data} 
        widget={widget} 
        error={error} 
        date={getDateAsString(slide.debut, slide.debut)}>
            {data == null ? 
                null :
                <div>
                    <DataBlock
                        valueN={data.caisseN}
                        valueN_1={data.caisseN_1}
                        libelle={"Total caisse :"}
                        unit={euroSymbol}/>
                    <ChartBlock
                        style={{height: Math.max(getBarChartHeight(data.modesPaiement.length), 500) +'px'}}
                        widget={widget}
                        unit={euroSymbol}
                        libelle={"Détail par mode de paiement :"}
                        chartTypes={['donut', 'bar']}
                        color='purple'
                        data={data.modesPaiement.map(el => {
                            return {
                                name:el.nom,
                                valueN:el.caisseN,
                                valueN_1:el.caisseN_1
                            }
                        })}/>
                </div>
            }
        </WidgetContent>
    )
}