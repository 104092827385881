import { EChartOption } from "echarts";
import { ReactECharts } from "./ReactECharts";
import { CSSProperties } from "react";
import ChartData from "../../models/chartData";
import { chartTextColor, chartTextColorDark, smallWidth } from "../../utils/Const";
import useWindowDimensions, { useWindowTheme } from "../../app/hooks";
import { getPolarChartColorTheme } from "../../utils/Charts";
import { formatNumberWithSpaces } from "../../utils/Numbers";

interface DonutChartProps {
    data: ChartData[];
    theme: string;
    style?: CSSProperties;
    unit?: string
}



export function DonutChart ({data, theme, style, unit=""} : DonutChartProps){

    const {width} = useWindowDimensions();
    let colors = getPolarChartColorTheme(theme);
    const fontSize = width < smallWidth ? 10 : 12;
    const radius = width < smallWidth ? ['55%', '90%'] : ['40%', '65%'];
    const center = width < smallWidth ? ['50%', '35%'] : ['50%', '50%'];
    const textColor = useWindowTheme() === "dark" ? chartTextColorDark : chartTextColor;

    style = {
        ...style,
        width:'96%'
    }

    let options: EChartOption = {
        grid: {
            containLabel: true
        },
        tooltip: {
            trigger: 'item',
            formatter: (el:any) => {
                return `<div>
                            <span>${el.data.name}</span>
                            <span style="margin-left: 20px;color:${el.color}; font-weight: bold;">${formatNumberWithSpaces(el.data.value) + unit}</span>
                        </div>`
            }
        },
        legend: {
            show: width < smallWidth,
            bottom:0,
            textStyle: {
                color:textColor,
                fontSize: fontSize
            },
            selectedMode: false
        },
        color: colors,
        series: [
            {
                type: 'pie',
                radius: radius,
                center: center,
                data: data.map(el => {return {name: el.name, value: el.valueN}}),
                label: {
                    show : width > smallWidth,
                    fontSize: fontSize,
                    color: textColor,
                    formatter: (el:any) => {
                        return el.name+'\n'+formatNumberWithSpaces(el.data.value) + unit
                    }
                },
            }
        ]
    }
  
    return  (
        <ReactECharts option={options} style={style}/> 
    )
  
}
