
import { ChartColorTheme } from "../models/ChartColorTheme";
import { smallWidth } from "./Const";



export const getChartColorTheme = (theme:string, darkTheme:boolean) : ChartColorTheme => {

    if (darkTheme) return getChartColorThemeDark(theme);

    let mainTheme = {text: "#8a8b93", line: "#d5d7e6"}

    switch(theme){
        case "purple" :
            return {
                ...mainTheme,
                backgroundN: "#a87de9AA",
                backgroundN_1: "#ead3fdAA",
                labelN: '',
                labelN_1: '',
                legendN: "#9d6fe3",
                legendN_1: "#9fa6ed"
            };
        case "blue2" :
            return {
                ...mainTheme,
                backgroundN: "#5eccc566",
                backgroundN_1: "#bbf2ef66",
                labelN: '',
                labelN_1: '',
                legendN: "#5eccc5",
                legendN_1: "#9edbd7"
            };
        case "orange" : 
            return {
                ...mainTheme,
                backgroundN: "#f5a278aa",
                backgroundN_1: "#f8d3c0aa",
                labelN: '',
                labelN_1: '',
                legendN: "#f5a278",
                legendN_1: "#f5c2a6",
            };
        case "green" : 
            return {
                ...mainTheme,
                backgroundN: "#8dc73aaa",
                backgroundN_1: "#c7f589aa",
                labelN: '',
                labelN_1: '',
                legendN: "#8dc73a",
                legendN_1: "#a0e344",
            };
        case "green2" : 
            return {
                ...mainTheme,
                backgroundN: "#379e7baa",
                backgroundN_1: "#a4dfcbaa",
                labelN: '',
                labelN_1: '',
                legendN: "#379e7b",
                legendN_1: "#a4dfcb",
            };
        case "pink" : 
            return {
                ...mainTheme,
                backgroundN: "#e96a9baa",
                backgroundN_1: "#f79ec0aa",
                labelN: '',
                labelN_1: '',
                legendN: "#e96a9b",
                legendN_1: "#f79ec0",
            };
        default : //bleu
            return {
                ...mainTheme,
                backgroundN: '#47a2ed66',
                backgroundN_1: "#a5c9e833",
                labelN: "",
                labelN_1: "",
                legendN: "#47a2ed",
                legendN_1: "#a5c9e8"
            }
    } 
}

const getChartColorThemeDark = (theme:string) : ChartColorTheme => {

    let mainTheme = {text: "#8a8b93", line: "#595a60"}

    switch(theme){
        case "purple" :
            return {
                ...mainTheme,
                backgroundN: "#7c65e666",
                backgroundN_1: "#c7f58966",
                labelN: '',
                labelN_1: '',
                legendN: "#866ff2",
                legendN_1: "#bdb0f7aa"
            };
        case "blue2" :
            return {
                ...mainTheme,
                backgroundN: "#2cd4c966",
                backgroundN_1: "#c1e3e166",
                labelN: '',
                labelN_1: '',
                legendN: "#2cd4c9",
                legendN_1: "#c1e3e1"
            };
        case "orange" : 
            return {
                ...mainTheme,
                backgroundN: "#8dc73a66",
                backgroundN_1: "#f8d3c066",
                labelN: '#FFE3D3',
                labelN_1:'#EC945C',
                legendN: "#f5a278",
                legendN_1: "#f8d3c0",
            };
        case "green" : 
            return {
                ...mainTheme,
                backgroundN: "#b2e36d66",
                backgroundN_1: "#e3f2d066",
                labelN: '',
                labelN_1: '',
                legendN: "#8dc73a",
                legendN_1: "#dff2c4",
            };
        case "green2" : 
            return {
                ...mainTheme,
                backgroundN: "#21a67966",
                backgroundN_1: "#c2eddf66",
                labelN: '',
                labelN_1: '',
                legendN: "#21a679",
                legendN_1: "#c2eddf",
            };
        case "pink" : 
            return {
                ...mainTheme,
                backgroundN: "#e96a9baa",
                backgroundN_1: "#f7b5ceaa",
                labelN: '',
                labelN_1: '',
                legendN: "#e96a9b",
                legendN_1: "#f7b5ce",
            };
        default : //bleu
            return {
                ...mainTheme,
                backgroundN: '#47a2ed66',
                backgroundN_1: "#9abcd966",
                labelN: "",
                labelN_1: "",
                legendN: "#47a2ed",
                legendN_1: "#9abcd9"
            }
    } 
}


export const getPolarChartColorTheme = (color: string) : string[] => {
    switch(color){
        case "purple" :
            return ['#d1addc', '#c276e1', '#c2bbfc', '#79429c', '#5d4fd1', '#7e13c9', '#926cc8', '#55309f', '#547ae7', '#9eb2e6'];
        case "orange" : 
            return ['#dae6b1', '#f8ff58', '#ffc31c', '#ffde00', '#bf9c4b', '#ebc8b6', '#f2c58e', '#e8c90a', '#faa788', '#f3b2aa'];
        case "green" : 
            return ['#e3f5e3', '#8cdb86', '#b4efaa', '#62c54c', '#e1d39b', '#efffa2', '#93d065', '#b7ff47', '#b2da00', '#e8e863'];
        default : //bleu
            return ['#c7d4e7', '#d1fafc', '#94a7c8', '#d1e8fb', '#26a9de', '#96bdcb', '#2da7ca', '#8ddae9', '#64d3f9', '#a6e8e2'];
    }
}


export const getBarChartHeight = (dataLength : number) : number => {
    const width = window.innerWidth;

    // if (dataLength > 10) {
    //     return (dataLength*50)
    // }

    if (dataLength > 5) {
        if (width <= smallWidth) return (dataLength*45);
        return (dataLength*60)
    }

    if (width <= smallWidth) return (dataLength*70);
    return (dataLength*90)
    
}

export const getLineChartHeight = () : number => {
    const width = window.innerWidth;

    if (width <= smallWidth) return 350;
    return 500
    
}
  

export const formatLabels = (labels: string[], maxLength: number) : string[] => {
    return labels.map((el: string) => el.substring(0, maxLength)+(el.length > maxLength ? "..." : ""));
}