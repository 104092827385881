import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../app/store';
import { User } from '../models/user';
import { ShopCA } from '../models/shop';
import { Group } from '../models/group';
import { Widget } from '../models/widget';
import { CAGeneralJJ7J30ExData } from '../models/shopData';

interface UserState  {
    active:boolean,
    email: string,
    multibase: boolean,
    shops: ShopCA[],
    groups: Group[],
    widgets: Widget[]
}

const initialState: UserState = {
    active:false,
    email: '',
    multibase: true,
    shops: [],
    groups: [],
    widgets: []
}

interface SetShopCAPayload {
  id: number,
  data: CAGeneralJJ7J30ExData | null
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action : PayloadAction<User>) => {
            return{
            ...state,
            active: true,
            multibase: action.payload.multibase,
            email: action.payload.email,
            shops: action.payload.shops.map(s => {return {...s, chiffreAffaires: null, caError: false}}),
            groups: action.payload.groups,
            widgets: action.payload.widgets
            }
        },
        unsetUser: (state) => {
            return{
            ...state,
            active: false,
            email: '',
            shops: [],
            groups: [],
            widgets: [],
            }
        },
        updateWidgets: (state, action  : PayloadAction<Widget[]>) => {
            return{
            ...state,
            widgets: action.payload,
            }
        },
        updateGroups: (state, action  : PayloadAction<Group[]>) => {
            return{
            ...state,
            groups: action.payload,
            }
        },
        setShopCA: (state, action: PayloadAction<SetShopCAPayload>) => {
            let index = state.shops.map(el => el.id).indexOf(action.payload.id);
            state.shops[index].chiffreAffaires = action.payload.data;
        },
    }

});

export const { setUser, unsetUser, updateWidgets, updateGroups, setShopCA } = userSlice.actions
export const selectUser = (state: RootState) => state.user
export default userSlice.reducer