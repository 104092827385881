import WidgetHeader from '../WidgetHeader';
import { DashboardWidget, DashboardWidgetSlide } from '../../models/widget';
import { useEffect, useState } from 'react';
import { CAGeneralData } from '../../models/shopData';
import { getCAEvolution } from '../../api/caisseApi';
import dayjs from 'dayjs';
import WidgetSlider from '../WidgetSlider';

import { Shop } from '../../models/shop';
import ChartBlock from '../ChartBlock';
import { getLineChartHeight } from '../../utils/Charts';
import useWindowDimensions, { useAppSelector } from '../../app/hooks';
import { smallWidth } from '../../utils/Const';
import { WidgetContent } from '../WidgetContent';
import { getDateAsString, getRefresh } from '../../utils/Widgets';

interface CAEvolutionProps {
    shopId: number,
    widget: DashboardWidget
}

interface CAEvolutionSlideProps {
    shopId: number,
    widget: DashboardWidget,
    slide: DashboardWidgetSlide,
    step: number
}

export default function CAEvolution ({shopId, widget} : CAEvolutionProps) {

    const step = 2;
    
    const [slides, setSlides] = useState<DashboardWidgetSlide[]>([
        {
            debut: dayjs().add(-92, 'day').format('YYYY-MM-DD'),
            fin: dayjs().add(-62, 'day').format('YYYY-MM-DD'),
            timestamp: null
        },
        {
            debut: dayjs().add(-61, 'day').format('YYYY-MM-DD'),
            fin: dayjs().add(-31, 'day').format('YYYY-MM-DD'),
            timestamp: null
        },
        {
            debut: dayjs().add(-30, 'day').format('YYYY-MM-DD'),
            fin: dayjs().format('YYYY-MM-DD'),
            timestamp: null
        }
        
    ])

    const height = (useWindowDimensions().width < smallWidth ? 200 : 205) + getLineChartHeight() +'px';
    
    return (
        <div className="Widget" style={{height:height}}>
            <WidgetHeader title="Chiffre d'affaires" widget={widget} searchable={false}/>
            <WidgetSlider widget={widget} defaultIndex={3}>
                {slides.map(s => {
                    return (
                    <CAEvolutionSlide 
                        widget={widget}
                        shopId={shopId} 
                        slide={s}
                        step={step}
                        key={s.debut+"_"+s.fin}/>)
                })}
            </WidgetSlider>
       </div>
    )
}

function CAEvolutionSlide ({shopId, widget, slide, step} : CAEvolutionSlideProps) {

    const [data, setData] = useState<CAGeneralData[] | null>(null);
    const [error, setError] = useState<string | null>(null);

    const groups = useAppSelector((state) => state.user.groups);
    const theme: string = groups.find(el => el.id === -shopId)?.color || 'blue';


    useEffect(() => {
        setData(null);
        setError(null);
        getCAEvolution(shopId, slide.debut, slide.fin, getRefresh(slide.timestamp, widget.refreshTime), step)
        .then((res) => {
            setData(res)
            slide.timestamp = res.reduce((timestamp, d) => {
                if (d.timestamp < timestamp || timestamp == '') 
                    return d.timestamp;
                return timestamp
            }, '');
        })
        .catch((err) => {
            console.log(err);
            setError(err.message);
        });

        
    }, [widget.refreshTime, slide.debut, slide.fin]);

    return (
        <WidgetContent 
        data={data ? {shopsKO: getCAEvolutionShopsKO(data), key: "-", timestamp: slide.timestamp || ''} : null}
        widget={widget} 
        error={error} 
        date={getDateAsString(slide.debut, slide.fin, useWindowDimensions().width < smallWidth)}>
            {data == null ? 
                null :
                <div>
                    <ChartBlock
                        style={{height:getLineChartHeight()}}
                        widget={widget}
                        libelle={""}
                        chartTypes={['line', 'radar']}
                        color={theme}
                        sort={false}
                        data={data.map(el => {
                            return {
                                name:dayjs(el.dateDebut).format('DD/MM'),
                                valueN:el.chiffreAffairesN,
                                valueN_1:el.chiffreAffairesN_1
                            }
                    })}/>
                        
                </div>
            }
        </WidgetContent>
            
    )
}

function getCAEvolutionShopsKO (datas: CAGeneralData[] | null): Shop[] {
    let res: Shop[] = [];
    datas?.forEach(data => {
        data.shopsKO.forEach(s => {
            if (!res.find(el => el.id === s.id)) {
                res.push(s);
            }
        })
    })
    return res;
}