import { useEffect, useState } from 'react';
import WidgetHeader from '../WidgetHeader';
import WidgetSlider from '../WidgetSlider';
import { CaisseVenteData } from "../../models/shopData";
import Meteo, { SmallDataBlock, SmallDataBlockContainer } from '../DataBlock';
import dayjs from 'dayjs';
import { getDateAsString, getDateRange, getRefresh } from '../../utils/Widgets';
import { DashboardWidget, DashboardWidgetSlide } from '../../models/widget';
import { getCaisseVente } from '../../api/caisseApi';
import WidgetSearchFrame from '../WidgetSearchFrame';
import useWindowDimensions from '../../app/hooks';
import { euroSymbol, smallWidth } from '../../utils/Const';
import { WidgetContent } from '../WidgetContent';

interface CaisseVenteProps {
    shopId: number,
    widget: DashboardWidget
}

interface CaisseVenteSlideProps {
    widget: DashboardWidget
    slide: DashboardWidgetSlide
    shopId: number;
}


export default function CaisseVente({shopId, widget} : CaisseVenteProps) {

    let [slides, setSlides] = useState<DashboardWidgetSlide[]>(getDateRange(new Date().toISOString(), 4, 4).map((el) => {return {debut: el, fin: el, timestamp: null}}));
    let [fixedSlide, setFixedSlide] = useState<DashboardWidgetSlide>({debut: '@EXERCICEDEBUT@', fin: dayjs().format('YYYY-MM-DD'), timestamp: null});
    
    const height = (useWindowDimensions().width < smallWidth ? 365 : 400 ) + 'px';

    return (
       <div className="Widget" style={{height: height}}>
            <WidgetHeader title="Caisse de vente" widget={widget} withFixedFrame={true}/>
            {widget.search ? 
                <WidgetSearchFrame widget={widget} withDateFin={true}>
                    {widget.searchDateDebut && widget.searchDateFin ?
                        <CaisseVenteSlide 
                            widget={widget}
                            shopId={shopId} 
                            slide={{debut: widget.searchDateDebut, fin: widget.searchDateFin, timestamp: ""}}/> 
                        : null
                    }   
                </WidgetSearchFrame>
            :  
            ( widget.fixedFrame ? 
                <CaisseVenteSlide 
                    widget={widget}
                    shopId={shopId} 
                    slide={fixedSlide}
                    />
                :
                <WidgetSlider widget={widget} defaultIndex={slides.map (el => el.debut).indexOf(dayjs().format('YYYY-MM-DD'))}>
                    {slides.map(s => {
                        return (
                        <CaisseVenteSlide 
                            widget={widget}
                            slide= {s}
                            shopId={shopId} 
                            key={s.debut} />)
                    })}
                </WidgetSlider>
            )
            }
       </div>
    )
}


function CaisseVenteSlide ({shopId, widget, slide} : CaisseVenteSlideProps) {

    const [data, setData] = useState<CaisseVenteData | null>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        setData(null);
        setError(null);
        getCaisseVente(shopId, slide.debut, slide.fin, getRefresh(slide.timestamp, widget.refreshTime))
        .then((res) => {
            setData(res)
            slide.timestamp = res.timestamp;
        })
        .catch((err) => {
            console.log(err);
            setError(err.message);
        });
        
    }, [widget.refreshTime, slide.debut, slide.fin]);

    return (
        <WidgetContent
        data={data} 
        widget={widget} 
        error={error} 
        date={slide.debut === "@EXERCICEDEBUT@" ? "Exercice complet": getDateAsString(slide.debut, slide.fin)}>
            {data == null ? 
                null :
                <div>
                    <Meteo meteoN={data.idMeteoN} meteoN_1={data.idMeteoN_1}/>
                    <SmallDataBlockContainer>
                        <SmallDataBlock
                            valueN={data.nbVenteN}
                            valueN_1={data.nbVenteN_1}
                            libelle="Nombre de ventes"/>
                        <SmallDataBlock
                            unit={euroSymbol}
                            valueN={data.montantPanierMoyN}
                            valueN_1={data.montantPanierMoyN_1}
                            libelle="Montant du panier moyen"/>
                        <SmallDataBlock
                            valueN={data.nbPieceN}
                            valueN_1={data.nbPieceN_1}
                            libelle="Nombre de pièces"/>
                        <SmallDataBlock
                            unit='%'
                            valueN={data.pourcRemiseMoyN}
                            valueN_1={data.pourcRemiseMoyN_1}
                            libelle="Pourcentage de remise moyen"/>
                        <SmallDataBlock
                            unit={euroSymbol}
                            valueN={data.montantTotN}
                            valueN_1={data.montantTotN_1}
                            libelle="Montant total"/>
                        <SmallDataBlock
                            unit={euroSymbol}
                            valueN={data.margeN}
                            valueN_1={data.margeN_1}
                            libelle="Marge totale"/>
                    </SmallDataBlockContainer>
                </div>
            }
        </WidgetContent>
    )
}