import { EChartOption } from "echarts";
import { ReactECharts } from "./ReactECharts";
import { formatLabels, getPolarChartColorTheme } from "../../utils/Charts";
import { CSSProperties } from "react";
import ChartData from "../../models/chartData";
import useWindowDimensions from "../../app/hooks";
import { chartTextColor, smallWidth } from "../../utils/Const";

interface PolarChartProps {
    data: ChartData[];
    theme: string;
    style?: CSSProperties;
}



export function PolarChart ({data, theme, style} : PolarChartProps){

    const {width} = useWindowDimensions();
    const fontSize = width < smallWidth ? 10 : 12;
    const centerY =  width < smallWidth ? '43%' : '45%';
    const colors = getPolarChartColorTheme(theme);

    style = {
        ...style,
        width:'96%'
    }

    let labels = formatLabels(data.map(el => el.name), 15);
    
  
    let options: EChartOption = {
        grid: {
            left: 10,
            top: 0,
            right:10,
            bottom: 0,
            containLabel :true
        },
        legend: {
            bottom: 0,
            data: labels,
            textStyle:{
                fontSize : fontSize,
                color : chartTextColor
            }
        },
        color: colors,
        polar: {
            radius: [30, '75%'],
            center :['50%', centerY]
            // radius: [30, '75%'],
            // center :['50%','45%']
        },
        angleAxis: {
            startAngle: 75,
            max: Math.max(...data.map(el => el.valueN))*1.1,
            axisLabel:{
                fontSize : fontSize,
            }
        },
        radiusAxis: {
            show: false,
            type: 'category',
        },
        tooltip: {formatter: '{a} <br><b>{c}</b>'},
        series: data.map((el, index) => {

            let formattedData =  Array(data.length);
            formattedData[index] = el.valueN;

            return {
                name: labels[index],
                type:'bar',
                stack: 'stack',
                coordinateSystem: 'polar',
                data:formattedData,
                label: {
                    color: chartTextColor, //TODO : ajuster en fonction de la couleur du bg
                    show: true,
                    position: 'middle',
                    formatter: '{c}',
                    fontSize : fontSize
                  }
            }
            
        }),
    }
  
    return  (
        <ReactECharts option={options} style={style}/> 
    )
  
}
