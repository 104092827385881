import { useNavigate } from "react-router-dom";
import { logout } from "../api/authApi";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { setShopCA, unsetUser } from "../features/UserSlice";
import { MainHeader } from "../components/Headers";
import { ChevronIcon, GroupIcon, LogoutIcon, RefreshIcon, SettingsIcon, ShopIcon } from "../components/Icons";

import '../styles/home.css';
import { useEffect, useState } from "react";
import { getCAGeneralJJ7J30Ex } from "../api/caisseApi";
import dayjs from "dayjs";
import { CAGeneralJJ7J30ExData } from "../models/shopData";
import { SmallLoader } from "../components/Loaders";
import { User } from "../models/user";
import { getGroupColorTheme } from "../utils/Shops";
import { euroSymbol } from "../utils/Const";
import { ShopCA } from "../models/shop";
import { Group } from "../models/group";
import { formatNumberWithSpaces } from "../utils/Numbers";
import { setWidgets } from "../features/DashboardSlice";

interface ShopRowProps {
  id:number,
  name: string
  data: CAGeneralJJ7J30ExData | null
  refresh: (id: number) => void
}

interface ShopBlockProps {
    title: string,
    children: React.ReactNode[],
    icon: React.ReactNode
}

export default function Home() {

  const dispatch = useAppDispatch();
  const shops: ShopCA[] = useAppSelector((state) => state.user.shops);
  const groups: Group[] = useAppSelector((state) => state.user.groups);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout()
    .then((res) => {
      dispatch(unsetUser())
    });
  }

  const refreshShopData = (id: number) => {
    let lstShop: number[] = [id];
    if (id < 0) lstShop = groups.find(el => el.id === -1*id)?.shops || [];
    else if (id == 0) lstShop = shops.map(el =>el.id);
    
    lstShop.forEach(s => {
        dispatch(setShopCA({id:s, data:null}));
        getCAGeneralJJ7J30Ex(s, dayjs().format('YYYY-MM-DD'), true)
        .then((res) => dispatch(setShopCA({id: s, data:res})))
        .catch((err) => {
            console.log(err);
        });

        })

  }


    useEffect(() => {
        shops.forEach(s => {
            if (!s.chiffreAffaires) {
                getCAGeneralJJ7J30Ex(s.id, dayjs().format('YYYY-MM-DD'), true)
                .then((res) => dispatch(setShopCA({id:s.id, data:res})))
                .catch((err) => {
                    console.log(err);
                });
            }
        });
         dispatch(setWidgets([]));
    }, []);

  return (
    <div>
        <MainHeader title="Accueil">
            <button onClick={() => navigate('/parametres')}><SettingsIcon/></button>
            <button onClick={handleLogout}><LogoutIcon/></button>
        </MainHeader>
        <div className="ShopRow header">
            <div></div>
            <div></div>
            <div className="CATable">
                <div className="caJ">J</div>
                <div className="caJ7">J-7</div>
                <div className="caJ30">J-30</div>
                <div className="caEx">Exercice</div>
            </div>
        </div>
        <ShopBlock title="Mes groupes" icon={<GroupIcon/>}>
            <div></div><div></div>
            <ShopRow id={0} name={"Général"} data={aggregateCAGeneralJJ7J30ExData(shops.map(s => s.chiffreAffaires))} refresh={refreshShopData}/>
            {groups.map(g => {
                let groupShops = shops.filter(el => g.shops.includes(el.id))
                return <ShopRow id={-1*g.id} name={g.name} key={-1*g.id} data={aggregateCAGeneralJJ7J30ExData(groupShops.map(s => s.chiffreAffaires))} refresh={refreshShopData}/>
            })}
        </ShopBlock>
        <ShopBlock title="Mes magasins" icon={<ShopIcon/>}>
            {
                [...shops]
                .sort((a,b) => a.name > b.name ? 1 : -1)
                .map(s => {
                return <ShopRow id={s.id} name={s.name} key={s.id} data={s.chiffreAffaires} refresh={refreshShopData}/>
                })
            }
        </ShopBlock>
    </div>
  );
}


function ShopBlock ({title, children, icon} :ShopBlockProps) {

    const [display, setDisplay] = useState<boolean>(true);

    return (
        <div className={"ShopBlock" + (display? "" : " hidden")} onClick={() => setDisplay(!display)}>
            <div className="blockHeader">
                {icon}
                <span>{title}</span>
                <button><ChevronIcon/></button>
            </div>
            <div className="content">
                <div>
                    {children}
                </div>
            </div>
        </div>
    )

}

function ShopRow ({id, name, data, refresh}: ShopRowProps) {

    const navigate = useNavigate();
    const user: User = useAppSelector((state) => state.user);

    const color: string | null = id < 0 ? user.groups.find(el => el.id === -id)?.color || null : null ;

    return (
        <div className={"ShopRow" + (data == null || data?.date == "" ? " disabled": "")} style={getGroupColorTheme(color)} onClick={(e) => {e.stopPropagation();navigate('/favoris/'+id)}}>
            <div>
                {data != null ?
                    <div className={"bulle " + (data.date == "" ? "red" : (data.shopsKO.length > 0 ? ' orange': 'green'))}></div>
                    : <SmallLoader/>
                }
            </div>
            <div>
                <span>{name}</span>
            </div>
            {data?.date == "" ?
                <div className="refresh" onClick={(e) => {e.stopPropagation();refresh(id)}}>{data === null ? null : <button><RefreshIcon/></button>}</div> :
                <div className="CATable">
                    <div className="caJ">{data ? formatNumberWithSpaces(data.chiffreAffairesJN) + euroSymbol : "-"}</div>
                    <div className="caJ7">{data ? formatNumberWithSpaces(data.chiffreAffairesJ7N) + euroSymbol : "-"}</div>
                    <div className="caJ30">{data ? formatNumberWithSpaces(data.chiffreAffairesJ30N) + euroSymbol : "-"}</div>
                    <div className="caEx">{data ? formatNumberWithSpaces(data.chiffreAffairesExN) + euroSymbol : "-"}</div>
                    <div className="caJ caN_1">{data && data.chiffreAffairesJN_1 > 0 ? formatNumberWithSpaces(data.chiffreAffairesJN_1) + euroSymbol : "-"}</div>
                    <div className="caJ7 caN_1">{data && data.chiffreAffairesJ7N_1 > 0 ? formatNumberWithSpaces(data.chiffreAffairesJ7N_1) + euroSymbol : "-"}</div>
                    <div className="caJ30 caN_1">{data && data.chiffreAffairesJ30N_1 > 0 ? formatNumberWithSpaces(data.chiffreAffairesJ30N_1) + euroSymbol : "-"}</div>
                    <div className="caEx caN_1"> - </div>
                    {/* <div className="caEx caN_1">{data && data.chiffreAffairesExN_1 > 0 ? formatNumberWithSpaces(data.chiffreAffairesExN_1) + euroSymbol : "-"}</div> */}
                    {/* <div className="refresh" onClick={(e) => {e.stopPropagation();refresh(id)}}>{data === null ? null : <button><RefreshIcon/></button>}</div> */}
                </div>
            }
            <div className="overlay" onClick={(e) => {e.stopPropagation();}}></div>
        </div>
    )
}

function aggregateCAGeneralJJ7J30ExData (datas: (CAGeneralJJ7J30ExData | null)[]) : CAGeneralJJ7J30ExData | null {

    if (datas.filter(el => el === null).length> 0) return null;

    let res: CAGeneralJJ7J30ExData = {
        date: "",
        key: "",
        timestamp: dayjs().format('YYYY-MM-DD HH:mm'),
        chiffreAffairesJN: 0,
        chiffreAffairesJ7N: 0,
        chiffreAffairesJ30N: 0,
        chiffreAffairesExN: 0,
        chiffreAffairesJN_1: 0,
        chiffreAffairesJ7N_1: 0,
        chiffreAffairesJ30N_1: 0,
        chiffreAffairesExN_1: 0,
        shopsKO: []
    };

    datas.forEach(d => {
        if (d) {
            if (d.shopsKO.length > 0) res.shopsKO = [...res.shopsKO, ...d.shopsKO]
            else {
                res.chiffreAffairesJN += d.chiffreAffairesJN;
                res.chiffreAffairesJ7N += d.chiffreAffairesJ7N;
                res.chiffreAffairesJ30N += d.chiffreAffairesJ30N;
                res.chiffreAffairesExN += d.chiffreAffairesExN;
                res.chiffreAffairesJN_1 += d.chiffreAffairesJN_1;
                res.chiffreAffairesJ7N_1 += d.chiffreAffairesJ7N_1;
                res.chiffreAffairesJ30N_1 += d.chiffreAffairesJ30N_1;
                res.chiffreAffairesExN_1 += d.chiffreAffairesExN_1;
                res.timestamp = d.timestamp < res.timestamp ? d.timestamp : res.timestamp;
                res.date = d.date;
            }
        }

    })

    res.shopsKO = res.shopsKO.filter((el, i) => {
        return res.shopsKO.findIndex(shop => shop.id === el.id) === i;
    })

    return res;
}