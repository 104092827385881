import { EChartOption } from "echarts";
import { ReactECharts } from "./ReactECharts";
import { formatLabels } from "../../utils/Charts";
import { CSSProperties } from "react";
import ChartData from "../../models/chartData";
import { smallWidth } from "../../utils/Const";
import useWindowDimensions, { useWindowTheme } from "../../app/hooks";
import { ChartColorTheme } from "../../models/ChartColorTheme";
import { formatNumberWithSpaces } from "../../utils/Numbers";

interface BarChartProps {
    data: ChartData[];
    theme: string;
    style?: CSSProperties;
    unit?:string;
}



export function BarChart ({data, theme, style, unit=""} : BarChartProps){

    const {width} = useWindowDimensions();
    let colors = getBarChartColorTheme(theme, useWindowTheme() === "dark");

    const fontSize = width < smallWidth ? 10 : 12;

    style = {
        ...style,
        width:'96%'
    }
  
    let options: EChartOption = {
        legend: {
            show:true,
            itemWidth: 0,
            itemHeight: 0,
            selectedMode:false,
            data: [
                {
                    name: 'N',
                    textStyle: {
                        color: colors.legendN,
                        fontWeight: 500,
                        fontSize: 13
                    }
                },
                {
                    name: 'N-1',
                    textStyle: {
                        color: colors.legendN_1,
                        fontWeight: 500,
                        fontSize: 13
                    }
                }
            ]
        },
        grid: {
            left: 10,
            top: 20,
            right:10,
            bottom: 0,
            containLabel :true
        },
        xAxis: {
            type: 'value',
            axisLabel: {
                formatter: '{value}',
                fontSize : fontSize,
            },
            splitLine: {
                lineStyle: {
                    color: colors.line
                }
            }
        },
        yAxis: {
            type: 'category',
            inverse: true,
            data: formatLabels(data.map((el: ChartData) => el.name), 15),
            axisLabel: {
                interval : 0,
                height: 50,
                fontSize : fontSize,
                color:colors.text
    
            },
            axisLine: {
                lineStyle: {
                    color:colors.line
                }
            }
        },
        series: [
            {
                type: 'bar',
                name:"N",
                data: data.map((el: ChartData) => el.valueN),
                label: {
                    show :true,
                    fontSize : fontSize,
                    position: 'inside',
                    color :colors.labelN,
                    formatter: (el: any) => formatNumberWithSpaces(el.data)+unit
                },
                itemStyle : { 
                    color: colors.backgroundN,
                }
            },
            {

                type: 'bar',
                name:"N-1",
                label:{
                    show: true,
                    fontSize : fontSize,
                    position: 'inside',
                    color :colors.labelN_1
                },
                data: data.map((el: ChartData) => el.valueN_1),
                itemStyle : { 
                    color: colors.backgroundN_1
                }
            }
        ]
        }
  
    return  (
        <ReactECharts option={options} style={style}/> 
    )
  
}


const getBarChartColorTheme = (color : string, darkTheme:boolean) : ChartColorTheme =>{

    if (darkTheme) return getBarChartColorThemeDark(color);

    let theme = {text : "#8a8b93", line: "#d5d7e6"}

    switch(color){
        case "purple" :
            return {
                ...theme,
                backgroundN: "#7c86ec",
                backgroundN_1: "#bbc0f1",
                labelN: '#BEBFE6',
                labelN_1: '#7880EC',
                legendN: "#7c86ec",
                legendN_1: "#bbc0f1"
            };
        case "orange" : 
            return {
                ...theme,
                backgroundN: "#f5a278",
                backgroundN_1: "#f8d3c0",
                labelN: '#FFE3D3',
                labelN_1:'#EC945C',
                legendN: "#f5a278",
                legendN_1: "#f8d3c0",
            };
        case "green" : 
            return {
                ...theme,
                backgroundN: "#b2e36d",
                backgroundN_1: "#e3f2d0",
                labelN: '#E4FCBF',
                labelN_1: '#B1CC87',
                legendN: "#b2e36d",
                legendN_1: "#e3f2d0",
            };
        default : //bleu
            return {
                ...theme,
                backgroundN: "#9DD3F3",
                backgroundN_1: "#d3ecfa",
                labelN: '#F4F8FE',
                labelN_1: '#8AB3C7',
                legendN: "#9DD3F3",
                legendN_1: "#d3ecfa",
            };
    } 
  
}

const getBarChartColorThemeDark = (color : string) : ChartColorTheme =>{

    let theme = {text: "#8a8b93", line: "#595a60"}

    switch(color){
        case "purple" :
            return {
                ...theme,
                backgroundN: "#5e66b5",
                backgroundN_1: "#888dba",
                labelN: '#BEBFE6',
                labelN_1: '#4e559c',
                legendN: "#7c86ec",
                legendN_1: "#9ca3ebB0"
            };
        case "orange" : 
            return {
                ...theme,
                backgroundN: "#cf835d",
                backgroundN_1: "#c99e87",
                labelN: '#FFE3D3',
                labelN_1:'#b86f40',
                legendN: "#f5a278",
                legendN_1: "#f5bfa3b0",
            };
        case "green" : 
            return {
                ...theme,
                backgroundN: "#7fa647",
                backgroundN_1: "#9eb087",
                labelN: '#E4FCBF',
                labelN_1: '#658237',
                legendN: "#b2e36d",
                legendN_1: "#d7ecbbB0",
            };
        default : //bleu
            return {
                ...theme,
                backgroundN: "#4e86b5",
                backgroundN_1: "#96aab5",
                labelN: '#bccce3',
                labelN_1: '#427199',
                legendN: "#9DD3F3",
                legendN_1: "#BFE3F8B0",
            };
    }
  
}