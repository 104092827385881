import { CSSProperties } from "react";
import { ReactECharts } from "./ReactECharts";
import useWindowDimensions, { useWindowTheme } from "../../app/hooks";
import { smallWidth } from "../../utils/Const";
import { GaugeChartColorTheme } from "../../models/ChartColorTheme";


interface GaugeChartProps {
    percent: number;
    theme: string;
    style?: CSSProperties;
}



export function GaugeChart ({percent, theme, style} : GaugeChartProps){

    const {width} = useWindowDimensions();
    const height = width < smallWidth ? '100px' : '150px';
    const lineWidth = width < smallWidth ? 18 : 30 ;
    const fontSize = width < smallWidth ? 22 : 30 ;

    const colors:GaugeChartColorTheme = getGaugeChartColorTheme(theme, useWindowTheme() === "dark");

    style = {
        ...style,
        height: height,
        width:'96%',
    }

    const options = {
        series: [
          {
            type: 'gauge',
            radius:'120%',
            center: ['50%', '80%'],
            startAngle: 180,
            endAngle: 0,
            pointer: {
              show: false
            },
            progress: {
              show: true,
              overlap: false,
            },
            axisLine: {
              lineStyle: {
                width: lineWidth,
                color: [
                  [1, colors.backgroundColor]
                ]
              }
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
            },
            data: [
              {
                value: percent,
                itemStyle: {
                  color: colors.mainColor,
                },
                detail: {
                  fontSize: fontSize,
                  offsetCenter: [0, '-10%'],
                  valueAnimation: true,
                  formatter: function (value: number) {
                    return Math.round(value) + '%';
                  },
                  color: 'inherit'
                },
              }
            ],
          }
        ]
      };
  
    return  (
        <ReactECharts option={options} style={style}/> 
    )
  
}

const getGaugeChartColorTheme = (color:string, darkTheme:boolean) : GaugeChartColorTheme => {

  let colors: GaugeChartColorTheme = {backgroundColor: darkTheme ? "#373737" : "#e6ebf8", mainColor: ""}

  switch (color) {
      case "purple":
          return {
              ...colors,
              mainColor: "#a87de9",
          }
      case "blue2":
          return {
              ...colors,
              mainColor: "#71badb",
          }
      case "orange":
          return {
              ...colors,
              mainColor: "#f5a278",
          }
      case "green": 
          return {
              ...colors,
              mainColor: "#9fcf5a",
          }
      case "green2": 
          return {
              ...colors,
              mainColor: "#379e7b",
          }
      case "pink":
          return {
              ...colors,
              mainColor: "#e96a9b",
          }
      default:
          return {
              ...colors,
              mainColor:"#9dd3f3",
          }
  }
}

