import { DashboardWidget } from '../../models/widget';

import WidgetHeader from '../WidgetHeader';
import { CaisseLocationData } from '../../models/shopData';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { getCaisseLocation } from '../../api/caisseApi';
import { SingleDataBlock } from '../DataBlock';
import { WidgetContent } from '../WidgetContent';
import { getRefresh } from '../../utils/Widgets';
import { euroSymbol } from '../../utils/Const';

interface AvancementRetoursProps {
  shopId: number,
  widget: DashboardWidget
}

export default function CALocationPrevisionnel ({shopId, widget} : AvancementRetoursProps) {

    const date = dayjs().format('YYYY-MM-DD');
    const [data, setData] = useState<CaisseLocationData | null>(null);
    const [error, setError] = useState<string | null>(null);

    const height = 190 +'px';

    useEffect(() => {
        setData(null);
        setError(null);
        getCaisseLocation(shopId, date, date, getRefresh(data?.timestamp || null, widget.refreshTime))
        .then((res) => setData(res))
        .catch((err) => {
            console.log(err);
            setError(err.message);
        });
        
    }, [widget.refreshTime, date]);

    return (
       <div className="Widget" style={{height:height}}>
            <WidgetHeader title="CA de location en cours" widget={widget} searchable={false} duplicable={false}/>
            <WidgetContent data={data} widget={widget} error={error}>
                {data == null ? 
                    null :

                    <SingleDataBlock
                        unit={euroSymbol}
                        value={data.CAPrevisionRetour}/>

                }
            </WidgetContent>      
       </div>
    )
}